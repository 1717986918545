[dir=ltr] .course-plots[data-v-70f05a22] {
  padding-left: 0px !important;
}[dir=rtl] .course-plots[data-v-70f05a22] {
  padding-right: 0px !important;
}
.custom-checkbox-footer label[data-v-70f05a22] {
  font-size: 0.9rem !important;
}
.container-uploads svg[data-v-70f05a22] {
  width: 70px;
}
[dir] .b-form-tags.form-control[data-v-70f05a22] {
  padding: 1rem 1rem !important;
}
.beforeUpload .icon[data-v-70f05a22] {
  width: 50px !important;
}
.area-lessons[data-v-70f05a22] {
  min-height: 400px;
}